import { combineReducers, configureStore } from "@reduxjs/toolkit";
import  { userLoginReducer } from "./reducers/authreducer";
import { addPrivacyUrlReducer } from "./reducers/privacy";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { editGenderReducer } from "./reducers/user/editGender";
import { getAdminProfileReducer } from "./reducers/admin/myProfile";
import { adminLogoutReducer } from "./reducers/admin/logout";
import { getMetaDataReducer } from "./reducers/meta/getMetaData";
import { addInterestsReducer } from "./reducers/meta/addInterests";
import { deleteInterestReducer } from "./reducers/meta/deleteInterest";
import { updateSubscriptionReducer } from "./reducers/meta/updateSubscriptions";
import { filterUserReducer } from "./reducers/user/filterUsers";
import { getAllUserReducer } from "./reducers/user/getAllUsers";
import { getDashboardStatsReducer } from "./reducers/home/dasboardStats";
import { getAllReportedUsersReducer } from "./reducers/user/getAllReportedUser";
import { getSubscriptionHistoryReducer } from "./reducers/subscription/subscriptionHistory";
import { blockUserReducer } from "./reducers/user/blockUser";
import { activeSubscriptionReducer } from "./reducers/subscription/activeSubscription";
import { updateofferDateReducer } from "./reducers/meta/updateOfferDate";
import { getUserSubscriptionHistoryReducer } from "./reducers/subscription/getUserSubscriptionHisttory";
import { tablePaginationReducer } from "./table/pagination";
import { getHourlyActiveReducer } from "./reducers/user/activeHourlyUsers";
import { getWeeklyActiveReducer } from "./reducers/user/weeklyActiveUsers";
import { getWeeklyRegisterReducer } from "./reducers/user/weeklyRegisterUsers";
import { getAllNotificationReducer } from "./reducers/notification/getAllNotification";
import { editNotificationReducer } from "./reducers/notification/editNotification";
import { createNotificationReducer } from "./reducers/notification/createNotification";
import { getSingleNotificationReducer } from "./reducers/notification/singleNotification";
import { sendNotificationReducer } from "./reducers/notification/sendNotifcation";
import { getDeletedUsersReducer } from "./reducers/user/deletedUsers";
import { deleteUserReducer } from "./reducers/user/deleteUser";
import { activePremiumStatsReducer } from "./reducers/subscription/activePremiumStats";
import { toggleFreePlanReducer } from "./reducers/meta/toggleFreePlan";
import { freePlanLogsReducer } from "./reducers/meta/freePlanLogs";
import { updateAppVersionReducer } from "./reducers/meta/updateAppVersion";
import { getAdminLogsReducer } from "./reducers/admin/authLogs";
import { getAdminListReducer } from "./reducers/admin/adminList";
import { getAllReferReducer } from "./reducers/meta/getAllRefer";
import { createReferReducer } from "./reducers/meta/createRefer";
import { addAdminPermissionReducer } from "./reducers/admin/addPermission";
import { removeAdminPermissionReducer } from "./reducers/admin/removePermission";
import { createAdminReducer } from "./reducers/admin/createAdmin";
import { blockAdminReducer } from "./reducers/admin/blockAdmin";
import { addFAQReducer } from "./reducers/meta/addFaq";
import { deleteFaqReducer } from "./reducers/meta/deleteFaq";
import { getAllCountryReducer } from "./reducers/meta/getAllCountry";
import { singleCountryReducer } from "./reducers/meta/singleCountry";
import { addCountryReducer } from "./reducers/meta/addCountry";
import { uploadFileReducer } from "./reducers/uploadFile";
import { createBlogReducer } from "./reducers/meta/blog/createBlog";
import { getAllBlogsReducer } from "./reducers/meta/blog/getAllBlogs";
import { editBlogReducer } from "./reducers/meta/blog/editBlog";
import { getSingleBlogReducer } from "./reducers/meta/blog/getSingleBlog";

// user stats
import { getUserStatsReducer } from "./reducers/user/userStats";
import { getUserFeedbackReducer } from "./reducers/user/getUserFeedback";
import { userKpiReducer } from "./reducers/user/userKpi";
import { deleteBlogReducer } from "./reducers/meta/blog/deleteBlog";
import { getSlowDownUsersReducer } from "./reducers/user/getSlowDownUsers";


const rootreducers = combineReducers({
  // admin
  getAdminProfile:getAdminProfileReducer,
  adminLogout:adminLogoutReducer,
  adminAuthLogs:getAdminLogsReducer,
  adminList:getAdminListReducer,
  addPermission:addAdminPermissionReducer,
  removePermission:removeAdminPermissionReducer,
  createAdmin:createAdminReducer,
  blockAdmin:blockAdminReducer,
  // users
  auth: userLoginReducer,
  addPrivacy: addPrivacyUrlReducer,
  editUserGender:editGenderReducer,
  filterUsers:filterUserReducer,
  getAllUser:getAllUserReducer,
  getAllReportedUser:getAllReportedUsersReducer,
  blockUser:blockUserReducer,
  hourlyActiveUsers:getHourlyActiveReducer,
  weeklyActiveUsers:getWeeklyActiveReducer,
  weeklyRegisterUsers:getWeeklyRegisterReducer,
  getDeletedUsers:getDeletedUsersReducer,
  deleteUser:deleteUserReducer,

  // user stats
  userStats:getUserStatsReducer,
  //user feedback
  userFeedback:getUserFeedbackReducer,

  //slow down user stats
  slowDownUserStats:getSlowDownUsersReducer,

  // kpi stats userKpiSlice
  kpiStats:userKpiReducer,

  // meta
  getMetaData:getMetaDataReducer,
  addInterest:addInterestsReducer,
  deleteInterest:deleteInterestReducer,
  updateSubscription:updateSubscriptionReducer,
  dashBoardStat:getDashboardStatsReducer,
  updateOfferDate:updateofferDateReducer,
  toggleFreePlan:toggleFreePlanReducer,
  freePlanLogs:freePlanLogsReducer,
  updateAppVersion:updateAppVersionReducer,
  allRefer:getAllReferReducer,
  createRefer:createReferReducer,
  addFaq:addFAQReducer,
  deleteFaq:deleteFaqReducer,
  allCountry:getAllCountryReducer,
  singleCountry:singleCountryReducer,
  addCountryPricing:addCountryReducer,
  uploadFile:uploadFileReducer,

  // blog
  createBlog:createBlogReducer,
  getAllBlogs:getAllBlogsReducer,
  editBlog:editBlogReducer,
  getSingleBlog:getSingleBlogReducer,
  deleteBlog:deleteBlogReducer,


  // subscription
  subscriptionHistory:getSubscriptionHistoryReducer,
  activeSubscription:activeSubscriptionReducer,
  userSubscription:getUserSubscriptionHistoryReducer,
  activePremiumStats:activePremiumStatsReducer,

  // table
  tablePagination:tablePaginationReducer,

  // notitfication
  allNotification:getAllNotificationReducer,
  editNotification:editNotificationReducer,
  createNewNotification:createNotificationReducer,
  singleNotification:getSingleNotificationReducer,
  sendNotification:sendNotificationReducer
});

const store = configureStore({
  reducer: rootreducers,
  middleware:[thunk,logger]
});

export default store;
