import React, { useState, useEffect } from 'react';
import CallStats from './CallStats';
import TimeStats from './TimeStats';
import ChatStat from './ChatStat';
import MaleFemaleTimeStats from './MaleFemaleTimeStats';
import Header from '../Header';
import "../../Styles/alluser.css";
import { useDispatch, useSelector } from 'react-redux'; 
import { userKpiAction } from '../../redux/reducers/user/userKpi';
import { Toast } from "../Toast";

const UserKpi = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [snake, setSnake] = useState(false);

    const dispatch = useDispatch();

    const { message, status, loading, data } = useSelector(state => state.kpiStats); 

const toIST = (date) => {
    const offsetIST = 5 * 60 + 30; 
    const utcOffset = date.getTimezoneOffset();
    const totalOffset = offsetIST - utcOffset;
    date.setMinutes(date.getMinutes() + totalOffset);
    return date;
};

const getFirstDayOfMonth = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    return toIST(firstDay).toISOString().split('T')[0]; 
};

const getYesterday = () => {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    return toIST(now).toISOString().split('T')[0]; 
};

    const maxDate = getYesterday()
    useEffect(() => {
        const defaultStartDate = getFirstDayOfMonth();
        const defaultEndDate = getYesterday();
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);

        dispatch(userKpiAction({ startDate: defaultStartDate, endDate: defaultEndDate }));
    }, [dispatch]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'startDate') {
            setStartDate(value);
        } else if (name === 'endDate') {
            setEndDate(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (startDate && endDate) {
            dispatch(userKpiAction({ startDate, endDate }));
            setSnake(true);
        } else {
            setSnake(true);
        }
    };

    return (
        <>
            <Header selectedNumber={4} />
            <div className='container-kpi home-container'>
                <div className='dashBoard'>
                    <div className="date-selection w-100 margin-left">
                        <h2>Dashboard</h2>
                        <form onSubmit={handleSubmit} className="right-filter w25 flex-end">
                            <div className="str-date al-center">
                                <label htmlFor="startDate">Starting Date</label>
                                <input
                                    type="date"
                                    name="startDate"
                                    value={startDate}
                                    onChange={handleDateChange}
                                    // max={maxDate}
                                />
                            </div>
                            <div className="str-date al-center">
                                <label htmlFor="endDate">End Date</label>
                                <input
                                    type="date"
                                    name="endDate"
                                    value={endDate}
                                    onChange={handleDateChange}
                                    // max={maxDate}
                                />
                            </div>
                            <button className='btn-kpi' type="submit" disabled={loading}>Get KPI Stats</button>
                        </form>
                    </div>
                    {/* Pass kpiData to child components */}
                    <ChatStat data={data} />
                    <CallStats data={data} />
                    <TimeStats data={data} />
                    <MaleFemaleTimeStats data={data} />
                </div>
                <Toast open={snake} setOpen={setSnake} message={message} status={status} />
            </div>
        </>
    );
};

export default UserKpi;