import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import { Toast } from "../components/Toast";
import { BigLoader } from "../components/BigLoader";
import { useNavigate } from "react-router-dom";
import { getSlowDownUsersAction } from "../redux/reducers/user/getSlowDownUsers";

const SlowDownUserList = () => {
  const [slowDownList, setSlowDownList] = useState([]);
  const [totalFilterPages, setTotalFilterPages] = useState(0);
  const [toastOpen, setToastOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { message, loading, status } = useSelector((state) => state.filterUsers);
  const { data, loading: initialData } = useSelector((state) => state.getAllUser);
  const { page, limit } = useSelector((state) => state.tablePagination);

  const column = [
    {
      headerName: "Name",
      type:'objectValue',
      property: "userId.name",
      sorting: true,
      minWidth: 200,
    },
    {
        headerName: "User Id",
        type:'objectValue',
        property: "userId._id",
        minWidth: 100,
      },
    // {
    //   headerName: "dp",
    //   type: "image",
    //   property: "",
    //   minWidth: 200,
    // },
    {
      headerName: "slowDown Start-Time",
      type:'objectValue',
      property: "slowDownStartTime",
      minWidth: 200,
    },
    {
      headerName: "slowDown End-Time",
      type:'objectValue',
      property: "slowDownEndTime",
      minWidth: 200,
    },
    {
      headerName: "slowDown Type",
      type:'objectValue',
      property: "slowDownType",
      minWidth: 100,
    },
  ];

  const getSlowDownUser = async () => {
    try {
      const result = await dispatch(getSlowDownUsersAction({ page, limit }));
      const data = result?.payload?.data;
      setSlowDownList(data?.slowDownUser || []);
      setTotalFilterPages(data?.totalPages || 0);
    } catch (error) {
      console.error("Failed to fetch user feedback", error);
      setToastOpen(true);
    }
  };

  useEffect(() => {
    getSlowDownUser();
  }, [page, limit]);

  return (
    <>
      <div className="container-2">
        <Header selectedNumber={1} />
        <button className="btn-submit btn-back" onClick={() => navigate(-1)}>
          Back
        </button>
        {loading || initialData ? (
          <BigLoader />
              ) : (
                  <Table column={column} data={slowDownList}
                      loading={initialData}
                      totalPages={totalFilterPages}
                       redirect="_id" viewButton={false}
                  />
              )
        }
      </div>
      <Toast open={toastOpen} message={message} setOpen={setToastOpen} status={status} />
    </>
  );
};

export default SlowDownUserList;
